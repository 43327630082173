import React, { useState, useEffect } from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, User } from '@ant-design/icons';
import { Card } from 'antd';
import { QuestionCircleOutlined, MailOutlined, PhoneOutlined, PlusCircleFilled } from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';
import { Link } from 'react-router-dom';
import { FaGraduationCap, FaChalkboardTeacher, FaUniversity, FaBook, FaBuilding, FaUserGraduate, FaUsers, FaChartLine, FaMoneyBill, FaRegChartBar, FaReceipt, FaChartArea } from 'react-icons/fa';
import { MdAccountBalance, MdAdminPanelSettings, MdAppRegistration, MdEditDocument, MdTrendingDown, MdTrendingUp } from 'react-icons/md';

const Menu = (props) => {


  useEffect(() => {
    localStorage.setItem('title', 'Bem-vindo a Área de Serviços');
    localStorage.setItem('type', '1');
  }, [])

  const links = [
    {
      title: 'Estudante',
      icon: <FaGraduationCap style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: 'https://estudante.siga.uem.mz/auth',
      groups: ["SIGA-Estudante"],
    },
    {
      title: "Docente",
      icon: <FaChalkboardTeacher style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: "https://docente.siga.uem.mz/auth",
      groups: ["SIGA-Docente"],
    },
    {
      title: "DRA",
      icon: <FaUniversity style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: "https://dra.siga.uem.mz/auth",
      groups: ["SIGA-DRA"],
    },
    {
      title: "Registo Académico da Faculdade/Escola",
      icon: <FaBook style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: "https://rfe.siga.uem.mz/auth",
      groups: ["SIGA-RFE"],
    },
    {
      title: "Direcção da Faculdade/Escola",
      icon: <FaBuilding style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: "https://dfe.siga.uem.mz/auth",
      groups: ["SIGA-DFE"],
    },
    {
      title: "Direcção do Curso",
      icon: <FaUserGraduate style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: "https://dc.siga.uem.mz/auth",
      groups: ["SIGA-DC"],
    },
    {
      title: "Monitoramento",
      icon: <FaChartArea style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: "https://mrd.girp.uem.mz/auth",
      groups: ["GIRP-GAI", "GIRP-Chefe-Depto-PAF", "GIRP-Chefe-Depto-RP", "GIRP-Dir-FIN", "GIRP-Dir-UO", "GIRP-Gestor-FIN-UO", "GIRP-Administrador-UO","GIRP-Chefe-DAF","GIRP-Reitor", "GIRP-Vice-Reitor"],
    },
    {
      title: "Planificação de Orçamento",
      icon: <MdAccountBalance style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: "https://plan.girp.uem.mz/auth",
      groups: ["GIRP-Contabilista-UO","GIRP-Tec-UO","GIRP-Chefe-DAF","GIRP-Administrador-UO","GIRP-Dir-UO","GIRP-Dir-FIN"],
    },
    {
      title: "Distribuição de Receitas",
      icon: <MdAppRegistration style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: "https://dist.girp.uem.mz/auth",
      groups: ["GIRP-Tec-DFIN", "GIRP-Chefe-Depto-RP", "GIRP-Dir-FIN", "GIRP-Tesoureiro-Central", "GIRP-Chefe-Depto-PAF"],
    },
    {
      title: "Gestão de Despesas",
      icon: <MdTrendingDown style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: "https://rd.girp.uem.mz/auth",
      groups: ["GIRP-Contabilista-UO","GIRP-Tec-UO","GIRP-Chefe-DAF","GIRP-Administrador-UO","GIRP-Dir-UO","GIRP-Dir-FIN","GIRP-Tesoureiro-UO"],
    },
    {
      title: "Gestão de Receitas",
      icon: <MdTrendingUp style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: "https://gr.girp.uem.mz/auth",
      groups: ["GIRP-Contabilista-UO","GIRP-Tec-UO","GIRP-Chefe-DAF","GIRP-Administrador-UO","GIRP-Dir-UO","GIRP-Dir-FIN","GIRP-Tesoureiro-UO"],
    },
    {
      title: "Gestão de Dissertações",
      icon: <MdEditDocument style={{ fontSize: '60px', color: '#047D4B' }} />,
      link: "http://localhost:3000/auth",
      groups: ["SGMP-ESTUDANTE"],
    },
  ];

  const handleNavigate = (link) => {
    window.open(link+'/'+sessionStorage.getItem('token'), '_blank');
  }

  const filteredLinks = links.filter(link => link.groups.some(group => sessionStorage.getItem('accessos').includes(group)));


  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-4  gap-4 p-2 sm:p-8">
        {filteredLinks.map((link, index) => (
          <Link onClick={()=>handleNavigate(link.link)} key={index}>
            <Card
              bordered={false}
              hoverable
              cover={link.icon}
              className="relative"
            >
              <div className="text-sm sm:text-lg text-center">{link.title}</div>

            </Card>
          </Link>
        ))}
      </div>
    </>
  );
};

export default Menu;