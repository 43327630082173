// src/Login.js
import axios from 'axios';
import 'antd/dist/reset.css';
import React from 'react';
import { Form, Input, Button, notification, Divider } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getProfilePicture } from '../../API/StaticGetters';
import { sub } from 'date-fns';

const Login = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [dados, setDados] = React.useState({})

  const submit = useMutation({
    mutationFn: (data) => {
      setLoading(true)
      return axios.post(`https://auth.uem.mz/uem-ldap/authentication/`, {
        user: data.username,
        password: data.password
      })
        .then(res => res.data)
    },
    onSuccess: (data) => {
      console.log(data)
      setLoading(false)
      localStorage.setItem('title', 'Bem-vindo a Área de Serviços');
      localStorage.setItem('type', '1');
      localStorage.setItem('url', `https://api.girpdev.uem.mz`);
      sessionStorage.setItem('token', data.token)
      sessionStorage.setItem('user', dados.username)
      sessionStorage.setItem('accessos', data.acessos)
      goIn()
    },
    onError: (error) => {
      verifyUserOnSiga(dados.username, dados.password)
    }
  })

  const verifyUserOnSiga = async (username, password) => {
    const url = 'https://api.siga.uem.mz/account/token/'
    axios.post(url, 
      {
        username: username,
        password: password  
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      resetPasswordLdap(username, password)
    }).catch((error) => {
      notification.error({
        message: 'Erro',
        description: 'Login Falhou'
      })
      setLoading(false)
    })
  }

  const resetPasswordLdap = async (username, password) => {
    const url = 'https://auth.uem.mz/uem-ldap/change-user-password/'
    axios.post(url, 
      {
        uid: username,
        new_password: password  
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token 8d0873f840f1c4362ca29849946333d34e98765b83cd16a644bae27c86a0255f`
        }
      }
    ).then((response) => {
      submit.mutate({username: username, password: password})
    }).catch((error) => {
      notification.error({
        message: 'Erro',
        description: 'Login Falhou'
      })
      setLoading(false)
    })
  }

  const goIn = async () => {
    navigate('/')
  }

  const handleSubmit = async (e) => {
    submit.mutate(e)
  };

  return (
    <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
      <div className="flex h-screen bg-white sm:bg-gray-100">
        <div className="m-auto w-full sm:w-auto">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            className="bg-white sm:shadow-md px-8 pt-0 pb-8 sm:w-96"
            style={{ borderRadius: '15px' }}
          >
            <div className='flex flex-row justify-center items-center gap-2 mb-2 pt-6 pb-2'>
              <img src={require('../../assets/logo/logo-v2.png')} alt="logo" className="w-20 sm:w-20" />
            </div>
            <p className="text-center text-lg sm:text-lg font-bold mb-1" style={{ color: 'black' }}>ÁREA DE SERVIÇOS | UEM</p>
            <p className="text-center text-sm sm:text-sm " style={{ color: 'black' }}>INTRODUZA AS CREDENCIAIS DE ACESSO</p>
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Introduza o seu nome de usuário!' }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Usuário"
                onChange={(e) => setDados({ ...dados, username: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Introduza a sua senha!' }]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
                onChange={(e) => setDados({ ...dados, password: e.target.value })}
              />
            </Form.Item>

            <Form.Item className='my-2 p-0'>
              <Button loading={loading} style={{ background: '#047D4B', margin: 0 }} className='text-white' htmlType="submit" block>
                Entrar
              </Button>
            </Form.Item>
            {/*<p className='flex flex-row justify-center gap-2 text-center w-full mt-5'>
              <a className="text-sm sm:text-sm" style={{ color: '#047D4B' }} onClick={() => navigate('/account/resetpass')}>Recuperar Senha</a>
            </p>*/}
          </Form>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Login;
